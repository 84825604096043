
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "60f08a1f-7df7-45ff-9688-40c81caa38c3"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/opt/build/repo/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
